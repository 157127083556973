import React from "react"
import { useIntl, FormattedMessage, Link } from "gatsby-plugin-intl"
import Seo from "../../components/SEO"
import Header from "../../components/HeaderC"
import PageNav from "../../components/PageNav"

const Team = (props) => {

    const intl = useIntl()

    return (
        <div>
            <Seo
                title={intl.formatMessage({ id: "team.title.seo" })}
            />
            <Header />

            <div>

                <div className="sir-breadcrumbs">
                    <Link class="text-sir-black-50" to="/"><FormattedMessage id="global.sir" /></Link>
                    <span class="text-sir-black-25 mx-1">/</span>
                    <Link class="text-sir-black-50" to="/great-love"><FormattedMessage id="global.menu.greatlove" /></Link>
                    <span class="text-sir-black-25 mx-1">/</span>
                    <FormattedMessage id="global.menu.team" />
                </div>

                <div className="sir-section sir-layout-2-3 text-center">
                    <h1 className="title-l uppercase mb-8">
                        <FormattedMessage id="team.title" />
                    </h1>
                    <ul>
                        <li>Eda Tuulberg &mdash; <i><FormattedMessage id="team.role1" /></i></li>
                        <li>Andres Heljula &mdash; <i><FormattedMessage id="team.role2" /></i></li>
                        <li>Jaak Kaevats &mdash; <i><FormattedMessage id="team.role3" /></i></li>
                        <li>Omar Neiland &mdash; <i><FormattedMessage id="team.role4" /></i></li>
                        <li>Helen Tammemäe &mdash; <i><FormattedMessage id="team.role5" /></i></li>
                    </ul>

                    <h2 className="title mt-16 mb-8"><FormattedMessage id="team.subtitle2" /></h2>

                    <ul>
                        <li>Maarja Kangro</li>
                        <li>kiwa</li>
                        <li>Peeter Laurits</li>
                        <li>Tuul Sepp</li>
                        <li>Elin Sild</li>
                        <li>Johanna Lamp</li>
                        <li>Neeme Lopp</li>
                        <li>Mari Volens</li>
                        <li>Darja Andrejeva</li>
                        <li>Tõnis Tootsen</li>
                        <li>Maia Tammjärv</li>
                        <li>Michael Haagensen</li>
                    </ul>
                </div>




            </div>
            <PageNav />
        </div>
    )
}

export default Team;